export const HostMainSite = "https://sdavaykin.ru/";
export const MAIN_PATH = "/";
export const ERROR_PATH = "/error";
export const ENTRY_PATH = "/entry";
export const REGISTRATION_PATH = "/registration";
export const DOCUMENTS_PATH = "/profile/documents";
export const TEXTBOOKS_PATH = "/profile/textbooks";
export const CERTIFICATION_PATH = "/profile/certification";
export const PREPARATION_PATH = "/profile/preparation";
export const COURSES_PATH = "/profile/courses";
export const SUBJECTS_PATH = "/profile/subjects";
export const SUBJECTS_CREATE_PATH = "/profile/subjects/create";
export const SUBJECTS_EDIT_PATH = "/profile/subjects/edit";
export const LESSON_EDIT_PATH = "/profile/courses/lesson/edit";
export const STUDENTS_PATH = "/profile/students";
export const SUBJECT_PAGE_PATH = "/profile/courses/subjectsPage";
export const TEXT_BOOK_PATH = "/profile/textbook";
export const TEXT_BOOK_ADD_PATH = "/profile/textbook/add";
export const TEXT_BOOK_EDIT_PATH = "/profile/textbook/edit";
export const RATE_PATH = "/profile/rate";
export const typeAppMobile = "mobile";
export const typeAppDesktop = "desktop";
export const ADMIN = "admin";
