import React, { lazy, Suspense } from "react";
import { createBrowserRouter, Link, Navigate } from "react-router-dom";
import App from "./components/App/App";
import Error from "./components/Error/Error";
import {
  COURSES_PATH,
  ENTRY_PATH,
  ERROR_PATH,
  LESSON_EDIT_PATH,
  MAIN_PATH,
  RATE_PATH,
  STUDENTS_PATH,
  SUBJECT_PAGE_PATH,
  SUBJECTS_CREATE_PATH,
  SUBJECTS_EDIT_PATH,
  SUBJECTS_PATH,
  TEXT_BOOK_ADD_PATH,
  TEXT_BOOK_EDIT_PATH,
  TEXT_BOOK_PATH,
} from "./utils/const";
import Profile from "./components/Profile/Profile";
import Loading from "./components/Loading/Loading";
import ProfileCourses from "./components/Profile/ProfilePage/ProfileCourses";
import ProfileSubjects from "./components/Profile/ProfilePage/ProfileSubjects";
import TextBookEdit from "./components/TextBook/TextBookEdit";
import FinancePage from "./pages/FinancePage";

const SubjectEditor = lazy(() => import("./components/Profile/SubjectEditor/SubjectEditor"));
const SubjectPage = lazy(() => import("./components/SubjectPage/SubjectPage"));
const ProfileSubjectEdit = lazy(() => import("./components/Profile/ProfilePage/ProfileSubjectEdit"));
const ProfileSubjectCreate = lazy(() => import("./components/Profile/ProfilePage/ProfileSubjectCreate"));
const ProfileStudent = lazy(() => import("./components/Profile/ProfilePage/ProfileStudent/ProfileStudent"));
const ProfileStudents = lazy(() => import("./components/Profile/ProfilePage/ProfileStudents"));
const ProfileMain = lazy(() => import("./components/Profile/ProfilePage/ProfileMain"));
const Login = lazy(() => import("./components/Login/Login"));
const TextBookList = lazy(() => import("./components/TextBook/TextBookList"));
const TextBookAdd = lazy(() => import("./components/TextBook/TextBookAdd"));

const router = createBrowserRouter([
  {
    path: MAIN_PATH,
    element: <App />,
    errorElement: <Navigate to={ERROR_PATH} replace />,
    handle: { crumb: () => <Link to={MAIN_PATH}>Главная</Link> },
    children: [
      {
        path: MAIN_PATH,
        element: <Profile />,
        handle: { crumb: () => <Link to={MAIN_PATH}>Панель управления</Link> },
        children: [
          {
            path: MAIN_PATH,
            element: (
              <Suspense fallback={<Loading />}>
                <ProfileMain />
              </Suspense>
            ),
          },
          {
            path: STUDENTS_PATH,
            element: (
              <Suspense fallback={<Loading />}>
                <ProfileStudents />
              </Suspense>
            ),
          },
          {
            path: `${STUDENTS_PATH}/:id`,
            element: (
              <Suspense fallback={<Loading />}>
                <ProfileStudent />
              </Suspense>
            ),
          },
          {
            path: `${COURSES_PATH}`,
            element: (
              <Suspense fallback={<Loading />}>
                <ProfileCourses />
              </Suspense>
            ),
          },
          {
            path: `${COURSES_PATH}/:class`,
            element: (
              <Suspense fallback={<Loading />}>
                <ProfileCourses />
              </Suspense>
            ),
          },
          {
            path: `${SUBJECTS_PATH}`,
            element: (
              <Suspense fallback={<Loading />}>
                <ProfileSubjects />
              </Suspense>
            ),
          },
          {
            path: `${SUBJECTS_CREATE_PATH}`,
            element: (
              <Suspense fallback={<Loading />}>
                <ProfileSubjectCreate />
              </Suspense>
            ),
          },
          {
            path: `${SUBJECTS_EDIT_PATH}/:id`,
            element: (
              <Suspense fallback={<Loading />}>
                <ProfileSubjectEdit />
              </Suspense>
            ),
          },
          {
            path: `${SUBJECT_PAGE_PATH}/:class/:id`,
            element: (
              <Suspense fallback={<Loading />}>
                <SubjectPage />
              </Suspense>
            ),
          },
          {
            path: `${LESSON_EDIT_PATH}/:id`,
            element: (
              <Suspense fallback={<Loading />}>
                <SubjectEditor />
              </Suspense>
            ),
          },
          {
            path: `${TEXT_BOOK_PATH}`,
            element: (
              <Suspense fallback={<Loading />}>
                <TextBookList />
              </Suspense>
            ),
          },
          {
            path: `${TEXT_BOOK_ADD_PATH}`,
            element: (
              <Suspense fallback={<Loading />}>
                <TextBookAdd />
              </Suspense>
            ),
          },
          {
            path: `${TEXT_BOOK_EDIT_PATH}/:id`,
            element: (
              <Suspense fallback={<Loading />}>
                <TextBookEdit />
              </Suspense>
            ),
          },
          {
            path: `${RATE_PATH}`,
            element: (
              <Suspense fallback={<Loading />}>
                <FinancePage />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: ENTRY_PATH,
        element: (
          <Suspense fallback={<Loading />}>
            <Login />
          </Suspense>
        ),
        handle: { crumb: () => <Link to={ENTRY_PATH}>Вход</Link> },
      },

      {
        path: ERROR_PATH,
        element: <Error />,
        handle: { crumb: () => <Link to={ERROR_PATH}>Ошибка</Link> },
      },
    ],
  },
]);

export default router;
