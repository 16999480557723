import LogoImg from "../images/logo.svg";
import BurgerMenuClose from "../images/icon/burger-menu-close.svg";
import BurgerMenu from "../images/icon/burger-menu.svg";
import EntryIcon from "../images/icon/entry.svg";
import AvatarIcon from "../images/icon/avatar.svg";
import DecorLogin from "../images/decor/login.svg";
import SocialVk from "../images/social/vk-1.svg";
import SocialGoogle from "../images/social/google.png";
import SocialMail2 from "../images/social/mail-2.svg";
import SocialYandex from "../images/social/yandex.svg";
import SocialTinkoff from "../images/social/tinkoff.svg";
import SocialApple from "../images/social/apple.png";
import CatClass10 from "../images/cat/class10.svg";
import PopupCab from "../images/decor/popup-cab.svg";
import DecorReg1 from "../images/decor/reg1.svg";
import DecorReg2 from "../images/decor/reg2.svg";
import DecorReg3 from "../images/decor/reg3.svg";
import DecorReg4 from "../images/decor/reg4.svg";
import ImageDoc1 from "../images/doc1.jpg";
import ImageDoc2 from "../images/doc1.jpg";
import Image404 from "../images/img_404.svg";
import Men from "../images/icon/men.svg";
import Exit from "../images/icon/exit.svg";
import Doc1 from "../images/icon/doc1.svg";
import School from "../images/icon/school.svg";
import Textbook from "../images/icon/Textbook.svg";
import List from "../images/icon/list.svg";
import Pencil from "../images/icon/pencil.svg";
import CheckAdmin from "../images/icon/checka.svg";
import Refresh from "../images/icon/refresh-ccw.svg";
import Pay from "../images/icon/pay.svg";

const IconStore = {
  LogoImg,
  BurgerMenuClose,
  BurgerMenu,
  EntryIcon,
  AvatarIcon,
  DecorLogin,
  SocialVk,
  SocialMail2,
  SocialGoogle,
  SocialYandex,
  SocialTinkoff,
  SocialApple,
  CatClass10,
  PopupCab,
  DecorReg1,
  DecorReg2,
  DecorReg3,
  ImageDoc1,
  ImageDoc2,
  Image404,
  Men,
  Exit,
  Doc1,
  School,
  Textbook,
  List,
  Pencil,
  CheckAdmin,
  Refresh,
  DecorReg4,
  Pay,
};

export default IconStore;
