import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { requiredField } from "../../utils/validators/validators";
import ClassInput from "../Inputs/ClassInput";
import { useNavigate, useParams } from "react-router-dom";
import { TextBookService } from "../../services/TextBookService";
import OptionInput from "../Inputs/OptionInput";
import UploadImage from "../Inputs/UploadImage";
import UploadDoc from "../Inputs/UploidingDoc";
import { loadingStatus } from "../../store/appReducer";
import { useAppDispatch } from "../../store/hooks";

interface TextBookType {
  name: string;
  author: string;
  classNumber: number;
  subject: number;
  thumbnail: number;
  ZipDoc: string;
}

const TextBookEdit = () => {
  const dispatch = useAppDispatch();
  const [classId, setClassId] = useState<null | number>(null);
  const [textBookId, setTextBookId] = useState<null | number>(null);
  const [disciplines, setDisciplines] = useState<Array<any>>([]);
  const [thumbnail, setThumbnail] = useState<string | null>(null);
  const [zipName, setZipName] = useState<string | null>(null);

  const params = useParams();

  let id = Number(params.id);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    control,
    watch,
    resetField,
    setValue,
    reset,
    formState: { errors },
  } = useForm<TextBookType>();

  const onSubmit = async (data: TextBookType) => {
    console.log(data);
    dispatch(loadingStatus(true));
    const response = await TextBookService.edit({
      name: data.name,
      author: data.author,
      class_id: data.classNumber,
      discipline_id: data.subject,
      thumbnail: data.thumbnail,
      file: data.ZipDoc,
      book_id: textBookId,
    });
    dispatch(loadingStatus(false));

    navigate(-1);
  };

  const setDefaultData = async (id: number) => {
    const { data } = await TextBookService.getBookInfo(id);
    setTextBookId(data.id);
    setThumbnail(data.thumbnail?.url.slice(8));
    setZipName(data.zip_name);
    reset({
      name: data.name,
      author: data.author,
      classNumber: data.class.id,
      subject: data.discipline.id,
      thumbnail: data.thumbnail?.id,
    });

    const response = await TextBookService.getDisciplines(data.class.id);
    const disciplinesData = response.map((i: any) => ({
      value: i.id,
      label: i.name,
    }));
    setDisciplines(disciplinesData);
  };

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === "classNumber" && value.classNumber) {
        setClassId(value.classNumber);
      }
      if (name === "ZipDoc" && value.ZipDoc) {
        setZipName(null);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    async function getDisciplines(id: number) {
      if (classId) {
        const response = await TextBookService.getDisciplines(classId);
        const data = response.map((i: any) => ({
          value: i.id,
          label: i.name,
        }));
        setDisciplines(data);
      }
    }
    if (classId) {
      getDisciplines(classId);
    }
  }, [classId]);

  useEffect(() => {
    setDefaultData(id);
  }, []);

  return (
    <>
      <div className="subjects__title _h3">Редактирования учебника</div>

      <form className="textBook__form">
        <ul className="textBook__inner">
          <li className="textBook__item">
            <div className={`popup-cab__input-wrap input ${errors.name ? "_error" : ""}`}>
              <label className="input__label" htmlFor="name">
                Название
              </label>
              <input
                className="input__input popup-cab__input"
                type="text"
                placeholder="Название"
                {...register("name", requiredField)}
              />
              {errors.name && <div className="input__error">{errors.name.message}</div>}
            </div>
          </li>
          <li className="textBook__item">
            <div className={`popup-cab__input-wrap input ${errors.author ? "_error" : ""}`}>
              <label className="input__label" htmlFor="author">
                Автор
              </label>
              <input
                className="input__input popup-cab__input"
                type="text"
                placeholder="Автор"
                {...register("author", requiredField)}
              />
              {errors.author && <div className="input__error">{errors.author.message}</div>}
            </div>
          </li>
          <li className="textBook__item">
            <label className="input__label" htmlFor="classNumber">
              Класс
            </label>
            <div className={`popup-cab__input-wrap input ${errors.classNumber ? "_error" : ""}`}>
              <ClassInput control={control} />
            </div>
            {errors.classNumber && <div className="input__error">{errors.classNumber.message}</div>}
          </li>

          <li className="textBook__item">
            <label className="input__label" htmlFor="subject">
              Предмет
            </label>
            <div className={`popup-cab__input-wrap input ${errors.subject ? "_error" : ""}`}>
              <OptionInput control={control} data={disciplines} name={"subject"} />
            </div>
            {errors.subject && <div className="input__error">{errors.subject.message}</div>}
          </li>

          <li className="textBook__item">
            <label className="input__label" htmlFor="thumbnail">
              Обложка
            </label>
            <div className={`popup-cab__input-wrap input ${errors.thumbnail ? "_error" : ""}`}>
              <UploadImage
                register={register}
                reset={resetField}
                name={"thumbnail"}
                onSet={thumbnail}
                setValue={setValue}
              />
            </div>
            {errors.thumbnail && <div className="input__error">{errors.thumbnail.message}</div>}
          </li>
          <li className="textBook__item">
            <label className="input__label" htmlFor="ZipDoc">
              Zip-архив
            </label>
            <div className={`popup-cab__input-wrap input ${errors.ZipDoc ? "_error" : ""}`}>
              <UploadDoc control={control} register={register} reset={resetField} name={"ZipDoc"} setValue={setValue} />
            </div>
            {errors.ZipDoc && <div className="input__error">{errors.ZipDoc.message}</div>}

            {zipName && <div className="textBook__info">{`Текущий zip архив: ${zipName}`}</div>}
          </li>
        </ul>
        <div className="textBook__btns">
          <Button
            onClick={handleSubmit((data) => {
              onSubmit(data);
            })}
          >
            Сохранить
          </Button>
        </div>
      </form>
    </>
  );
};

export default TextBookEdit;
