import React, { useEffect, useState } from "react";
import { useAppDispatch } from "../store/hooks";
import { loadingStatus } from "../store/appReducer";
import { openErrorNotification, openSuccessNotification } from "../utils/Notification";
import { registrationAPI } from "../api/api";
import { SubmitHandler, useForm } from "react-hook-form";
import { numberField } from "../utils/validators/validators";

type classInfo = {
  extended_price: number;
  id: number;
  name: string;
  price: number | undefined;
  mentor_price: number | undefined;
};

type formData = {
  class_id: number;
  price: number;
  extended_price: number;
  mentor_price: number;
};

export default function FinancePage() {
  const [currentClass, setCurrentClass] = useState<classInfo | null>(null);
  const [listClass, setListClass] = useState<Array<classInfo> | null>(null);
  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<formData>();

  const fetch = async () => {
    try {
      dispatch(loadingStatus(true));
      const { data } = await registrationAPI.getClasses(false);
      setListClass(data);
    } catch (e) {
      openErrorNotification("Ошибка");
      console.error(e);
    } finally {
      dispatch(loadingStatus(false));
    }
  };

  const onSubmit: SubmitHandler<formData> = async (data) => {
    try {
      dispatch(loadingStatus(true));
      await registrationAPI.updatePrices({
        class_id: data.class_id,
        price: data.price,
        extended_price: data.extended_price,
        mentor_price: data.mentor_price,
      });
      openSuccessNotification("Успешное обновление данных");
      await fetch();
    } catch (e) {
      openErrorNotification("Ошибка");
      console.error(e);
    } finally {
      dispatch(loadingStatus(false));
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    reset({
      class_id: currentClass?.id,
      price: currentClass?.price,
      extended_price: currentClass?.extended_price,
      mentor_price: currentClass?.mentor_price,
    });
  }, [currentClass]);
  return (
    <>
      <div className="subjects__title _h3" style={{ marginBottom: "30px" }}>
        Управления тарифами
      </div>
      <div className="courses__subtitle">Выберите класс, чтобы редактировать</div>
      <div className="courses__wrap">
        <ul className="courses__list">
          {listClass &&
            listClass
              .sort((a, b) => a.id - b.id)
              .map((e) => (
                <li className="courses__item" key={e.id}>
                  <button
                    className={`courses__link  btn ${currentClass?.id === e.id ? "_active" : ""}`}
                    onClick={() => setCurrentClass(e)}
                  >
                    {e.name}
                  </button>
                </li>
              ))}
        </ul>
      </div>
      {currentClass && (
        <form className="rate" onSubmit={handleSubmit(onSubmit)}>
          <ul className="rate__list">
            <li className="rate__item">
              <span>Тариф основной: </span>
              <div className="rate__inner">
                <input
                  className="rate__input"
                  type="text"
                  placeholder="Не задано"
                  {...register("price", numberField)}
                />
                {errors.price && <div className="input__error">{errors.price.message}</div>}
              </div>
            </li>
            <li className="rate__item">
              <span>Доступ к расширенному материалу: </span>
              <div className="rate__inner">
                <input
                  className="rate__input"
                  type="text"
                  placeholder="Не задано"
                  {...register("extended_price", numberField)}
                />
                {errors.extended_price && <div className="input__error">{errors.extended_price.message}</div>}
              </div>
            </li>
            <li className="rate__item">
              <span>Ментор: </span>
              <div className="rate__inner">
                <input
                  className="rate__input "
                  type="text"
                  placeholder="Не задано"
                  {...register("mentor_price", numberField)}
                />
                {errors.mentor_price && <div className="input__error">{errors.mentor_price.message}</div>}
              </div>
            </li>
          </ul>
          <button className="rate__btn btn btn_4" type="submit">
            Сохранить
          </button>
        </form>
      )}
    </>
  );
}
